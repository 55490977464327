<template>
  <b-container class="b-card-forms">
    <b-row class="vh-100" align-h="center">
      <b-col align-self="center" cols="12" sm="9" lg="5">
        <b-card class="text-center error">
          <img class="webmag-logo-big" src="@/assets/logo-webmag-big.png" alt="webmag logo"/>
          <h3>{{ $t('deactivated.title') }}</h3>
          <p class="mb-2" v-html="$t('deactivated.desc')"></p>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'UserDeactivated',
};
</script>

<style scoped>

</style>
